<template>
    <invoiceTemplateForm :is-new="false" />
  </template>
  
  <script>
  import invoiceTemplateForm from "./invoiceTemplateForm.vue";
  
  export default {
    components: { invoiceTemplateForm },
  };
  </script>
  
  <style></style>
  